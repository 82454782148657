<template>
  <div>
    <AppBar :title="$t('statute.title')"/>
    <v-card flat class="rounded-xl pt-2 pb-4">
      <v-card-title>
        <v-btn color='secondary' :to="{ name: 'AddStatute' }" depressed rounded>{{ $t('statute.add') }}
        </v-btn>
      </v-card-title>
      <v-simple-table v-if="statutes.length > 0">
        <template v-slot:default>
          <thead>
          <tr>
            <th class='text-left'>{{ $t('statute.columns.name') }}</th>
            <th style='width: 100px'></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for='statute in statutes' :key='statute.id'>
            <td @click="$router.push({ name: 'EditStatute', params: { id: statute.id } })">{{ statute.name }}</td>
            <td>
              <v-btn depressed @click="$router.push({ name: 'EditStatute', params: { id: statute.id } })" rounded>
                {{ $t('statute.columns.edit') }}
              </v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>
<script>
import Service from '@/services'
export default {
  name: 'StatuteList',
  data () {
    return {
      statutes: []
    }
  },
  created () {
    this.getStatutes()
  },
  methods: {
    async getStatutes () {
      this.loading = true
      const countryCode = localStorage.getItem('tenantValue')
      try {
        const { data } = await Service.get(`/Regulations/Management?countryCode=${countryCode}`)
        this.statutes = data
      } catch (error) {}
      this.loading = false
    }
  }
}
</script>
